* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: Arial, Helvetica, sans-serif;
  }
  

  


  .hr-line{
    background: rgba(204,204,204,1);
    box-shadow: 0px 0px 4px 2px rgba(204,204,204,1)
  }