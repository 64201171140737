:root {
    --primary:   linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 300px;
  }
  
  .btn--primary {
    background-color: var(--primary);
    border: 10px solid var(--primary);
  }
  
  .btn--outline {
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    padding: 8px 20px;
    border: 30px;
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);
    transition: 250ms;
  }