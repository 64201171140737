.div-pricing-container{
    width: 100%;
    height: 50%;
    background-color:black;
}

.div-pricing-top-container{
    display: flex;
    flex-direction:column;
    align-items: center;
}

.div-pricing-top-container-h1{
    color: white;
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-family: Arial, Helvetica, sans-serif;
}

.div-pricing-container-top-p{
        color: white;
}

.div-pricing-container-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.div-pricing-bottom-box-row-1{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    margin-left: 40%;   
}

.toggle-button {
    margin: 1%;
    font-weight: bolder;
    font-size: larger;
    color:  white
}

.active{
    color:  white;
    border-bottom: 4px solid white;
    transition: all 0.2s ease-out;
    padding-bottom: 0.5rem;
    margin: 1%;
    font-weight: bolder;
    font-size: larger;
}

.div-pricing-bottom-box-row-2{
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: row;
    margin-top: 5%;
    margin-bottom: 10%;
}

.div-pricing-container-bottom-boxes-inner{
    width: 50%;
    height: 30%;
    color: white;
    background-color:  #151922;
    border-radius: 3px;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    margin-left: 15px;
}

.div-pricing-container-bottom-boxes-inner:hover{
    background-color: #191970;
}

.div-pricing-container-bottom–Name{
    padding: 15px;
}