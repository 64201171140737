.contactus-card{
    width: 100%;
    height: 500%;
    background-color: rgb(224,224,224);
    border: 10px solid rgb(224,224,224);
    
}
.div-contactus-container-top{
    display: flex;
    flex-direction:column;
    align-items: center;
    margin-top: 2.5rem;
    padding-bottom: 2rem;
}


.div-conatctus-container-top-h1 > h1{
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bolder;
}



.parent {
    width: 70%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    margin:0 auto;
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow:hidden;
    text-decoration: none;
    display: flex;
    flex-flow: row;
    padding: 30px;
    background-color: white;
    height: 500px;
    align-items: center;
    display: flex;
    direction: row;
    margin-bottom: 3rem;
  }

  .child_1 {
    margin-left: 2%;
    padding: 1rem 1rem;
    vertical-align: middle;
    width: 80%;
    height: 100%;
    display: flex;
    flex-direction: column;

  }

  .child_2 {
    margin-right: 1rem;
    padding: 1rem 1rem;
    vertical-align: middle;
    width: 50%;
    height: 100%;
    box-shadow: 0 6px 20px rgba(128, 128, 128, 0.25);
    border-radius: 10px;
    background-color: transparent;
    background-color:  #151922;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    
  }

.contactus__items{
    padding-bottom: 10px;
    margin-bottom: 10px;
}

 

  .form-control_item{
    margin-bottom: 10%;
    margin-left: 5%;
    width: 90%;
    border: none;
    border-bottom: 1px solid #d9d9d9;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
    -o-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  }

.form-controm-bottom-margin{
    margin-top: 0.1%;
    margin-left: 35%;
}

.form-control-textarea{
    resize: none;
}

.contactus_child_2_form{
    margin-top: 10%;
}

  .form-control-button{
    align-self: flex-end;
    margin-top: 2%;
    padding: 6px 20px;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    border-radius: 10px;
    border: 2px solid white;
    color: black;
    background-color: white;
  }

  .form-control-button:hover{
    background-color: #191970;
  }


  .contactus_info_top {
    align-items: start;
    margin-bottom: 20%;
  }


  .contactus_info_center{
    margin-bottom: 18%;
  }
  .contactus_info_center_div{
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }


  .contactus_info_center_icon_data{
    margin-left: 5%;
  }

 .social_media_link{
    margin-left: 10%;
    color: blue;
 }



[type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}




@media only screen and (max-width: 1024px) {

    .parent {
        width: 75%;
        box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
        margin:0 auto;
        -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
        filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
        border-radius: 10px;
        overflow:hidden;
        text-decoration: none;
        display: flex;
        flex-flow: column;
        padding: 30px;
        background-color: white;
        height: 500px;
        align-items: center;
      }
}



