img {
  max-width: 100%;
  max-height: 100%;
  display: block;
  padding-left: 10%;
  border-radius: 30%;
  
}

.hero-container {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 50vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 15%;
}


.hero-container-top{
  display: flex;
  flex-flow: row;
  width: 90%;
  height: 50vh;
  margin-bottom: 8%;
  margin-top: 10%;

}

.hero-container-top-left{
  width: 50%;
  padding-left: 2%;
}

.hero-container-top-right{
  width: 50%;
  border-radius: 5px solid black;
  
}

.hero-container-top-left > h1 {
  color: black;
  font-size: 50px;
  line-height: 1.0;
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 12%;
  margin-left: 8%;
  margin-bottom: 6%;
 
}


.hero-container div > h3 {
  margin-top: 8px;
  font-size: 20px;
  font-family:Arial, Helvetica, sans-serif;

}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

.parent-hero-div{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 2%;
  margin-left: 10%;
  margin-right: 10%;
}

.child-hero-div{
  margin: 10x;
  padding: 18px;
  display: inline;
  text-align: center;
  
}

.child-hero-div >img{
  padding: 25px;
  
}

.fa-cloud{
  margin-left: 1.7rem;
  font-size: 3.6rem;
}

.child-hero-div > h4{
margin-bottom: 10px;
}

.parent-hero-title{
  margin-left: 41%;
  width: 15%;
  margin-bottom: 1%;
  margin-top: 3%;
}

.parent-hero-paragraph{
  width: 60%;
  margin-left: 20%;
  margin-bottom: 1%;
  text-align: center;
}

.parent-hero-paragraph >p{
  font-weight: 600;
}

.hero-parent-container-div{
  width: 70%;
  height: 100%;
  background:rgb(224,224,224);
  -webkit-filter: drop-shadow(0 6px 10px  rgba((0,0,0,1)));
  filter: drop-shadow(0 6px 10px  rgba((0,0,0,1)));
  border-radius: 5px;

}


.hero-container-button{
  padding: 0.8rem  1rem;
  white-space: nowrap;
  background: black;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1rem;
  color: white;
  font-weight: 500;
  border: 30px;
}

.hero-container-button-div{
  margin-left: 15%;
  
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
  .hero-container > h3 {
    font-size: 20px;
    text-align:center
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > h3 {
    font-size: 20px;
    text-align:center
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}