.div-home-how-we-work-container{
    width: 100%;
    height: 50%;
    background-color: rgb(224,224,224);
}

.div-home-how-we-work-container-top{
    display: flex;
    flex-direction:column;
    align-items: center;
}

.div-home-how-we-work-container-top-h1 > h1{
color: black;
margin-top: 3rem;
margin-bottom: 2rem;
font-family: Arial, Helvetica, sans-serif;
}

.div-home-how-we-work-container-top-p{
    color: black;
    
}

.div-home-how-we-work-container-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div-home-how-we-work-container-bottom-box-row-1  {
   width: 80%;
   margin-top: 1%;
}


.div-home-how-we-work-container-bottom-boxes{
    width: 100%;
    display: flex;
    flex-direction:row;

}


.div-home-how-we-work-container-bottom-boxes-inner_1{
    width: 50%;
    background-color:  white;
    margin-left: 2%;
    margin-top: 3%;
    margin-bottom: 5%;
    border-radius: 5px;
    padding: 1%;
    
}

.div-home-how-we-work-container-bottom-boxes-inner_2{
    width: 50%;
    background-color:  white;
    margin-left: 2%;
    margin-top: 5%;
    margin-bottom: 3%;
    border-radius: 5px;
    padding: 1%;

  
}

.div-home-how-we-work-container-bottom-boxes-inner_3{
    width: 50%;
    background-color:  white;
    margin-left: 2%;
    margin-top: 3%;
    margin-bottom: 5%;
    border-radius: 5px;
    padding: 1%;
  
}
.div-home-how-we-work-container-bottom-boxes-inner_4{
    width: 50%;
    background-color:  white;
    margin-left: 2%;
    margin-top: 5%;
    margin-bottom: 3%;
    border-radius: 5px;
    padding: 1%;
}
.div-home-how-we-work-container-bottom-boxes-inner:hover{
    background-color: #191970;
}

.div-home-how-we-work-container-bottom-icon{
    color: black;
    text-align: center;
    margin-top: 10%;
    
}

.div-home-how-we-work-container-bottom-underline{
    color: black;
}

.div-home-how-we-work-container-bottom-Name{
    color: black;
    text-align: center;
    margin-top: 2%;
}

.div-home-how-we-work-container-bottom-p{
    color: black;
    text-align: center;
    margin-top: 10%;
}
