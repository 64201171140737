.testimonial .slick-arrow.slick-prev,
.testimonial .slick-arrow.slick-next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border-radius: 5px;
}
.testimonial .slick-prev::before,
.testimonial .slick-next::before {
  display: none;
}
.testimonial .slick-arrow.slick-next {
  right: -70px;
}
.testimonial .slick-arrow.slick-prev {
  left: -70px;
}
.testimonial .slick-dots li button:before {
  font-size: 15px;
}
.testimonial .slick-dots li {
  top: 15px;
}




.testimonial__container{
    width: 100%;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
    margin:0 auto;
    -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
    border-radius: 10px;
    overflow: hidden;
    text-decoration: none;
    display: flex;
    flex-flow: column;
    padding: 30px;
}

.testimonial-card{
  background: black;
  padding: 2rem;
  padding-bottom: 8%;
}

.div-testimonial-container-top{
  display: flex;
  flex-direction:column;
  align-items: center;
  margin-top: 2rem;
  padding-bottom: 2rem;
}


.div-testimonial-container-top-h1 > h1{
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
}

