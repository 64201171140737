.div-home-services-container{
    width: 100%;
    height: 140vh;
    margin-top: 9%;
    background-color: black;
}

.div-home-services-container-top{
    display: flex;
    flex-direction:column;
    align-items: center;
}

.div-home-services-container-top-h1{
margin-top: 14%;
color: white;
font-family: Arial, Helvetica, sans-serif;
margin-bottom: 2rem;
}

.div-home-services-container-top-p{
    color: white;
    margin-bottom: 2rem;
}

.div-home-services-container-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 70%;
    align-items: center;
}

.div-home-services-container-bottom-box-row-1  {
   width: 65%;
   height: 40%;
   margin-top: 1%;
}

.div-home-services-container-bottom-box-row-2{
    margin: 1%;
    width: 65%;
    height: 40%;
}


.div-home-services-container-bottom-boxes{
    width: 100%;
    display: flex;
    flex-direction:row;
    height: 100%;
}


.div-home-services-container-bottom-boxes-inner{
    width: 50%;
    margin-left: 15px;
    top:15px;
    background-color:  #151922;
    border-radius: 3px;
    box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  
}
.div-home-services-container-bottom-boxes-inner:hover{
    background-color: #191970;
}

.div-home-services-container-bottom-icon{
    color: white;
    height: 100px;
    width: 100px;
    background-color: white;
    border-radius: 100%;
    margin-top: 5%;
    margin-left: 35%;

    padding-right: 22px;
    padding-left: 20px;
    padding-top: 20px;
}

.serviceImg{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.div-home-services-container-bottom-underline{
    color: white;
}

.div-home-services-container-bottom-Name{
    color: white;
    text-align: center;
    margin-top: 2%;
}

.div-home-services-container-bottom-p{
    color: white;
    text-align: center;
    margin-top: 10%;
}
