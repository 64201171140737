.Navbar-container{
    background-color:black;
    width: 100%;
    height: 100px;
    position:fixed;
    z-index: 3;
}

.NavbarItems{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    padding: 0 30px;
    background-color: white;
    box-shadow: 0 5px  15px rgba(0,0,0,0,0.25);
    width: 100%;
    height: 80px;
    position: fixed;
    top:0;
    left: 50%;
    transform: translate(-50%);
    z-index: 999;
}

.logo{
    color: black;
    justify-self: start;
    cursor: pointer;
}

.logo-icon{
    margin-left: 0.2rem;
    font-size: 1.6rem;
}

.nav-menu{
    display: grid;
    grid-template-columns: repeat(6,auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.nav-links{
    text-decoration: none;
    color: black;
    padding: 0.7rem 1rem;
    white-space: nowrap;
}

.nav-links:hover{
    padding: 1.7rem;
    border-bottom: 6px solid black;
    transition: all 0.2s ease-out;
}

.fa-bars, .fa-times{
    color: black;
}

.menu-icons{
    display: none;
}

.nav-links-mobile{
    padding: 0.8rem  1rem;
    white-space: nowrap;
    background-color:black;
    border-radius: 30px;
    text-decoration: none;
    font-size: 1rem;
    color: white;
    font-weight: 500;
}

@media screen and (max-width:850px) {
    .NavbarItems{
        z-index: 99;
    }

    .nav-menu{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background-color: rgba(255,255,255,0.25);
        box-shadow: 0 8px 32px 0 rgba(31,38,135,0.37);
        backdrop-filter: blur(15px);
        border-radius: 13px;
        width: 100%;
        height: auto;
        position: absolute;
        top:0;
        left: -110%;
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        transition: all 0.3s ease-in-out;
    }

    .nav-menu.active{
        left: 0%;

    }


    .nav-links{
        display: block;
        widows: 100%;
        padding: 2rem 0;
        color: #222;
    }

    .nav-links:hover{
        background-color: blue;
        backdrop-filter: blur;
    }

    .menu-icons{
        display: block;
    }

    .nav-links-mobile{
        padding: 1.5rem;
        display: block;
        text-align: center;
        font-size: 1.3rem;
        font-weight: bold;
        width: 80%;
        margin: auto;
    }
}